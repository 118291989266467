import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { DateSchema } from "yup";
const { REACT_APP_API_URL } = process.env;

function NewUser() {
  const [newMemberId, setNewMemberId] = useState("");
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newHandicap, setNewHandicap] = useState("");
  const [newFederation, setNewFederation] = useState("");
  const [newPrivileges, setNewPrivileges] = useState("User");
  const [newGender, setNewGender] = useState("");
  const [newUserToken, setNewUserToken] = useState("");
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");
  const { authState, setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
 
  // inputs for user groups
  const [groupInputs, setGroupInputs] = useState([]);

  let history = useHistory();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          //Obtain list of Event Groups
          axios
            .get(`${REACT_APP_API_URL}invitegroupusers`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((response) => {
              setGroupInputs(response.data);
              setLoading(false);
            });
          window.scrollTo(0, 0);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  function handleGroupInputChange(e){
    let tmp = [...groupInputs];

    // checking if value is correct and then,updating value, and setting to use in useState
    if(e.target.value > 1) e.target.value = 1;
    if(e.target.value < 0) e.target.value = 0;

    tmp[e.target.name].val = parseInt(e.target.value); 

    setGroupInputs(tmp);
  }

  // useEffect(() => console.log(groupInputs), [groupInputs]);

  const addUser = () => {
    const handicapWithComma = newHandicap.replace(".", ",");
    setNewHandicap(handicapWithComma);
    const data = {
      memberId: newMemberId,
      name: newName,
      email: newEmail,
      password: newPassword,
      phone: newPhone,
      handicap: handicapWithComma,
      handicapDec: handicapWithComma,
      federation: newFederation,
      privileges: newPrivileges,
      gender: newGender,
      usertoken: newUserToken,
    };
    axios
      .post(`${REACT_APP_API_URL}users/`, data)
      .then((response) => {
        addInviteGroupListsData(response.data.id);
        setNewMemberId("");
        setNewName("");
        setNewEmail("");
        setNewPassword("");
        setNewPhone("");
        setNewHandicap("");
        setNewFederation("");
        setNewPrivileges("User");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        window.scrollTo(0, 0);
        handleVisible(error.response.data.msg, "danger");
      });
  };

  
  const addInviteGroupListsData = (userId) => {

    axios
      .put(
        `${REACT_APP_API_URL}invitegroupusers/${userId}`,
        { groupInputs },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((response) => {
        window.scrollTo(0, 0);
        handleVisible("User Created", "success");
        //history.push('/users');
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        window.scrollTo(0, 0);
        handleVisible(error.response.data.msg, "danger");
      });
  };


  return (
    <>
      <Alert show={visibleAlert} variant={variant}>
        {alertText}
      </Alert>
      {!loading && (
        <Container className="createUserPage">
          <Row>
            <Col>
              <Form className="formContainer">
                <label for="memberid">Member Id</label>
                <input
                  type="number"
                  placeholder="Member Id..."
                  autoComplete="off"
                  name="memberid"
                  value={newMemberId}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewMemberId(event.target.value);
                  }}
                />
                <label for="name">Name</label>
                <input
                  type="text"
                  placeholder="Name..."
                  autoComplete="off"
                  name="name"
                  value={newName}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewName(event.target.value);
                  }}
                />
                <label for="email">Email</label>
                <input
                  type="email"
                  placeholder="Email..."
                  autoComplete="off"
                  name="email"
                  value={newEmail}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewEmail(event.target.value);
                  }}
                />
                <label for="password">Password</label>
                <input
                  type="password"
                  placeholder="Password..."
                  autoComplete="off"
                  name="password"
                  value={newPassword}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewPassword(event.target.value);
                  }}
                />
                <label for="phone">Phone</label>
                <input
                  type="text"
                  placeholder="Phone..."
                  autoComplete="off"
                  name="phone"
                  value={newPhone}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewPhone(event.target.value);
                  }}
                />
                <label for="handicap">Handicap</label>
                <input
                  type="text"
                  placeholder="Handicap..."
                  autoComplete="off"
                  name="handicap"
                  value={newHandicap}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewHandicap(event.target.value);
                  }}
                />
                <label for="federation">Federation</label>
                <input
                  type="text"
                  placeholder="Federation..."
                  autoComplete="off"
                  name="federation"
                  value={newFederation}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewFederation(event.target.value);
                  }}
                />
                <label for="privileges">Privileges</label>
                <Form.Control
                  as="select"
                  id="inputCreateUser"
                  name="privileges"
                  value={newPrivileges}
                  onChange={(event) => {
                    setNewPrivileges(event.target.value);
                  }}
                >
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Control>

                <label for="gender">Gender</label>
                <Form.Control
                  as="select"
                  id="inputCreateUser"
                  name="gender"
                  value={newGender}
                  onChange={(event) => {
                    setNewGender(event.target.value);
                  }}
                >
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </Form.Control>
                <label for="usertoken">User Token</label>
                <input
                  type="text"
                  placeholder="User Token..."
                  autoComplete="off"
                  name="usertoken"
                  value={newUserToken}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewUserToken(event.target.value);
                  }}
                />
                <Card className="p-3">
                {groupInputs.map((value, key) => {
                  // return <p>{value.gid}, {value.group}, {value.val}</p>
                  return (
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalGroup1"
                    >
                      <Form.Label column sm={4}>
                        {value.group}
                      </Form.Label>
                      <Col sm={8}>
                        <input
                          type="number"
                          name={key}
                          min="0"
                          max="1"
                          autoComplete="off"
                          value={value.val}
                          id="inputGroupList"
                          onChange={(event) => {
                            handleGroupInputChange(event)
                          }}
                        />
                      </Col>
                    </Form.Group>
                  );
                })}
                </Card>
                <Button
                  variant="success"
                  ClassName="regular-button"
                  onClick={() => addUser()}
                >
                  {" "}
                  Add
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
      {loading && (
        <Container className="createEventPage">
          <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
        </Container>
      )}
    </>
  );
}

export default NewUser;
