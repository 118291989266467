import React, { useEffect, useRef, useState, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AuthContext } from "../helpers/AuthContext";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
const { REACT_APP_API_URL } = process.env;

function SendEmail() {
  const [groupName, setGroupName] = useState();
  const [body, setBody] = useState();
  const [subject, setSubject] = useState();

  const [listOfInviteEventGroups, setListOfInviteEventGroups] = useState([]);

  const [sent, setSent] = useState(false);

  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");
  const { authState, setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  let history = useHistory();

  const initialValue = useRef({
    subject: "",
    body: "",
    groupName: "demo",
  });

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });

          // Obtain list of groups
          axios
            .get(`${REACT_APP_API_URL}inviteeventgroups`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((response) => {
              setListOfInviteEventGroups(response.data);
            });

          setGroupName(initialValue.current.groupName);
          setBody(initialValue.current.body);
          setSubject(initialValue.current.subject);
          setLoading(false);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [groupName, setGroupName]);
  useEffect(() => {}, [body, setBody]);
  useEffect(() => {}, [subject, setSubject]);

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  const submit = () => {
    const sender = authState.id;
    const message = `Message`;

    const output = body;

    axios
      .post(
        `${REACT_APP_API_URL}mail/sendinvitemail`,
        {
          subject,
          message,
          output,
          groupName,
          sender,
        },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((response) => {
        setSent(true);
        handleVisible("Mails Sent", "success");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        handleVisible(error.response.data.msg, "danger");
      });
  };

  return (
    <>
      <Alert show={visibleAlert} variant={variant}>
        {alertText}
      </Alert>
      {!loading && (
        <Container className="sendInviteMail">
          <Form className="formContainer">
            <label for="groupName">Group</label>
            <select
              id="inputCreateMail"
              name="groupName"
              onChange={(event) => {
                setGroupName(event.target.value);
              }}
            >
              {listOfInviteEventGroups.map((value, key) => {
                return (
                  <option value={value.eventGroupName}>
                    {value.eventGroupName}
                  </option>
                );
              })}
            </select>
            <label for="subject">Subject</label>
            <input
              type="text"
              autoComplete="off"
              name="subject"
              value={subject}
              id="inputCreateMail"
              onChange={(event) => {
                setSubject(event.target.value);
              }}
            />
            <label>Body</label>
            <ReactQuill theme="snow" value={body || ""} onChange={setBody} />
            {!sent && (
              <Button
                variant="success"
                ClassName="regular-button"
                onClick={() => submit()}
              >
                {" "}
                Send Email
              </Button>
            )}
            {sent && (
              <Button variant="primary" ClassName="regular-button" disabled>
                {" "}
                Emails Sent
              </Button>
            )}
          </Form>
        </Container>
      )}
      {loading && (
        <Container className="createEventPage">
          <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
        </Container>
      )}
    </>
  );
}

export default SendEmail;
