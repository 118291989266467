import React from 'react';

function Footer() {
  return (
    <footer>
      <div className='container'>
        <p>El Paraiso Golf Club - Copyright 2021</p>
        <p>
          For more informations please contact us at <br />{' '}
          reservas@elparaisogolf.com
        </p>
      </div>
    </footer>
  );
}

export default Footer;
