import React, { useContext } from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
const { REACT_APP_API_URL } = process.env;

function Home() {
  const [listOfEvents, setListOfEvents] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  let history = useHistory();
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  today = today.toISOString().slice(0, 19);
  const { authState, setAuthState } = useContext(AuthContext);

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          // Obtain Groups that the User belongs
          getUserGroups(response.data.id);
        }
      });
    axios.get(`${REACT_APP_API_URL}events/`).then((response) => {
      setListOfEvents(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserGroups = (id) => {
    axios
      .get(`${REACT_APP_API_URL}invitegroupusers/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        setUserGroups(response.data);
      });
  };

  // function that returns true if user is in the group
  function checkInGroup(groupNumber){
    if (!groupNumber) return false;

    for (const group of userGroups) {
      // found group by its number, and value is 1, then user is in the group
      if (group.gid === groupNumber && group.val === 1){
        return true;
      }
    }

    // none of the groups were triggered, fallback value is false
    return false;
  }

  return (
    <div className="min-height">
      <Container className="pt-5">
        {listOfEvents.map((value, key) => {
          if ( checkInGroup(value.groupNumber) ) {
            return (
              <Card
                key={value.id}
                className="text-center mb-3 home-event-card"
                onClick={() => {
                  history.push(`/events/byId/${value.id}`);
                }}
              >
                <Card.Header className="home-event-card-header">
                  <Card.Text>
                    {new Date(value.date).toLocaleDateString("es-ES")}{" "}
                    {value.startTime.slice(0, 5)}
                  </Card.Text>
                  <Card.Text>
                    Total: {value.places_total} | Available:{" "}
                    {value.places_available} | Occupied: {value.places_occupied}{" "}
                    | Waitlist: {value.places_waitlist}
                  </Card.Text>
                </Card.Header>
                <Card.Body>
                  <Card.Title>{value.event}</Card.Title>
                  {/* <Card.Text>
                    {value.type} | {value.groupName}
                  </Card.Text> */}
                  {/* <Card.Text>
                    Course: {value.course} | Holes: {value.holes}
                  </Card.Text> */}
                  {/* <Button
                    variant='primary'
                    onClick={() => {
                      history.push(`/events/byId/${value.id}`);
                    }}
                  >
                    Details
                  </Button> */}
                </Card.Body>
                {/* <Card.Footer className='text-muted'>
                  Total: {value.places_total} | Available:{' '}
                  {value.places_available} | Occupied: {value.places_occupied} |
                  Waitlist: {value.places_waitlist}{' '}
                </Card.Footer> */}
              </Card>
            );
          } else {
            return null;
          }
        })}
      </Container>
    </div>
  );
}

export default Home;
