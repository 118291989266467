import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
const { REACT_APP_API_URL } = process.env;

function Login() {
  let history = useHistory();
  let { usertoken } = useParams();
  const { authState, setAuthState } = useContext(AuthContext);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");

  const initialValues = {
    memberId: "",
    password: "",
  };

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  const validationSchema = Yup.object().shape({
    memberId: Yup.number(),
    password: Yup.string().required(),
  });

  const onSubmit = (data) => {
    axios
      .post(`${REACT_APP_API_URL}users/login`, data)
      .then((response) => {
        localStorage.setItem("accessToken", response.data);
        if (usertoken) {
          axios
            .get(`${REACT_APP_API_URL}users/auth`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((response) => {
              if (response.data.error) {
                setAuthState({ ...authState, status: false });
                history.push(`/users/login`);
              } else {
                setAuthState({
                  name: response.data.name,
                  id: response.data.id,
                  privileges: response.data.privileges,
                  status: true,
                });
                console.log(`userId: ${response.data.id}`);
                // Save new hcp
                const data = {
                  memberId: "",
                  name: "",
                  email: "",
                  password: "",
                  phone: "",
                  handicap: "",
                  federation: "",
                  privileges: "",
                  gender: "",
                  usertoken: usertoken,
                };
                console.log(data);
                axios
                  .put(
                    `${REACT_APP_API_URL}users/${response.data.id}`,
                    { data },
                    {
                      headers: {
                        accessToken: localStorage.getItem("accessToken"),
                      },
                    }
                  )
                  .then((response) => {})
                  .catch(function (error) {
                    if (error.response) {
                      // Request made and server responded
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                    } else if (error.request) {
                      // The request was made but no response was received
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log("Error", error.message);
                    }
                    handleVisible(error.response.data.msg, "danger");
                  });
              }
            });
        }
        history.push("/");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        handleVisible(error.response.data.msg, "danger");
      });
  };

  return (
    <>
      <Alert show={visibleAlert} variant={variant}>
        {alertText}
      </Alert>
      <Container className="login-register-pages">
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form className="formContainer">
              <ErrorMessage name="memberId" component="span" />
              <Field
                id="inputCreateUser"
                name="memberId"
                placeholder="Member ID"
              />
              <ErrorMessage name="password" component="span" />
              <Field
                id="inputCreateUser"
                name="password"
                placeholder="Password"
                type="password"
              />
              <Button variant="success" type="submit">
                Login
              </Button>
            </Form>
          </Formik>
        </>
        <p>
          No account? Please register{" "}
          <a
            href="https://elparaisoeventreg.scala4.com/users/register"
            className="subtext-link"
          >
            here
          </a>
        </p>
      </Container>
    </>
  );
}

export default Login;
