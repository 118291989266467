import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { useHistory } from "react-router-dom";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
const { REACT_APP_API_URL } = process.env;

function Profile() {
  let { id } = useParams();

  let history = useHistory();

  const { authState, setAuthState } = useContext(AuthContext);

  const [newMemberId, setNewMemberId] = useState("");
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newHandicap, setNewHandicap] = useState("");
  const [newFederation, setNewFederation] = useState("");
  const [newBalance, setNewBalance] = useState("");
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");
  const [loading, setLoading] = useState(true);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          const loggedInId = response.data.id.toString();
          if (loggedInId !== id) {
            console.log(`loggedInId: ${typeof loggedInId}`);
            console.log(`id: ${typeof id}`);
            handleVisible("You don't have access to this page", "danger");
          } else {
            setAuthState({
              name: response.data.name,
              id: response.data.id,
              privileges: response.data.privileges,
              status: true,
            });
            // Obtain user Data
            axios
              .get(`${REACT_APP_API_URL}users/byId/${id}`)
              .then((response) => {
                setNewMemberId(response.data.memberId);
                setNewName(response.data.name);
                setNewEmail(response.data.email);
                setNewPassword("");
                setNewPhone(response.data.phone);
                setNewHandicap(response.data.handicap);
                setNewFederation(response.data.federation);
                setNewBalance(response.data.balance);
                setLoading(false);
              });
          }
        }
      });

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  const saveUser = () => {
    const handicapWithComma = newHandicap.replace(".", ",");
    setNewHandicap(handicapWithComma);
    const data = {
      memberId: newMemberId,
      name: newName,
      email: newEmail,
      password: newPassword,
      phone: newPhone,
      handicap: handicapWithComma,
      federation: newFederation,
      balance: newBalance,
    };
    axios
      .put(
        `${REACT_APP_API_URL}users/${id}`,
        { data },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((response) => {
        window.scrollTo(0, 0);
        handleVisible("Changes Saved", "success");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        handleVisible(error.response.data.msg, "danger");
      });
  };

  return (
    <>
      <Alert show={visibleAlert} variant={variant}>
        {alertText}
      </Alert>
      {!loading && (
        <Container className="createUserPage">
          {authState.id.toString() === id && (
            <Row>
              <Col>
                <Form className="formContainer">
                  <label for="memberid">Member Id</label>
                  <input
                    type="number"
                    autoComplete="off"
                    name="memberid"
                    value={newMemberId}
                    id="inputCreateUser"
                    disabled
                  />
                  <label for="name">Name</label>
                  <input
                    type="text"
                    placeholder="Name..."
                    autoComplete="off"
                    name="name"
                    value={newName}
                    id="inputCreateUser"
                    onChange={(event) => {
                      setNewName(event.target.value);
                    }}
                  />
                  <label for="email">Email</label>
                  <input
                    type="email"
                    placeholder="Email..."
                    autoComplete="off"
                    name="email"
                    value={newEmail}
                    id="inputCreateUser"
                    onChange={(event) => {
                      setNewEmail(event.target.value);
                    }}
                  />
                  <label for="password">Password</label>
                  <input
                    type="password"
                    placeholder="Password..."
                    autoComplete="off"
                    name="password"
                    value={newPassword}
                    id="inputCreateUser"
                    onChange={(event) => {
                      setNewPassword(event.target.value);
                    }}
                  />
                  <label for="phone">Phone</label>
                  <input
                    type="text"
                    placeholder="Phone..."
                    autoComplete="off"
                    name="phone"
                    value={newPhone}
                    id="inputCreateUser"
                    onChange={(event) => {
                      setNewPhone(event.target.value);
                    }}
                  />
                  <label for="handicap">Handicap</label>
                  <input
                    type="text"
                    placeholder="Handicap..."
                    autoComplete="off"
                    name="handicap"
                    value={newHandicap}
                    id="inputCreateUser"
                    onChange={(event) => {
                      setNewHandicap(event.target.value);
                    }}
                  />
                  <label for="federation">Federation</label>
                  <input
                    type="text"
                    placeholder="Federation..."
                    autoComplete="off"
                    name="federation"
                    value={newFederation}
                    id="inputCreateUser"
                    onChange={(event) => {
                      setNewFederation(event.target.value);
                    }}
                  />
                  <label for="balance">Balance</label>
                  <input
                    type="text"
                    autoComplete="off"
                    name="balance"
                    value={newBalance}
                    id="inputCreateUser"
                    disabled
                  />

                  <Button
                    variant="success"
                    ClassName="regular-button"
                    onClick={() => saveUser()}
                  >
                    {" "}
                    Save
                  </Button>
                </Form>
              </Col>
            </Row>
          )}
        </Container>
      )}
      {loading && (
        <Container className="createEventPage">
          <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
        </Container>
      )}
    </>
  );
}

export default Profile;
