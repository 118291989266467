import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import FriendLogin from "./pages/FriendLogin";
import NewEvent from "./pages/NewEvent";
import EditEvent from "./pages/EditEvent";
import Event from "./pages/Event";
import Users from "./pages/Users";
import NewUser from "./pages/NewUser";
import EditUser from "./pages/EditUser";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import SendInviteMail from "./pages/SendInviteMail";
import SendParticipantsEmail from "./pages/SendParticipantsEmail";
import SendEmail from "./pages/SendEmail";
import Groups from "./pages/Groups";
import NewGroup from "./pages/NewGroup";
import EditGroup from "./pages/EditGroup";
import GroupTypes from "./pages/GroupTypes";
import NewGroupType from "./pages/NewGroupType";
import EditGroupType from "./pages/EditGroupType";
import EmailTemplates from "./pages/EmailTemplates";
import EditEmailTemplate from "./pages/EditEmailTemplate";
import NewEmailTemplate from "./pages/NewEmailTemplate";
import { AuthContext } from "./helpers/AuthContext";
import { useState, useEffect } from "react";
import axios from "axios";
//import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
//import env from 'react-dotenv';

// React Bootstrap Components
import Container from "react-bootstrap/Container";
const { REACT_APP_API_URL } = process.env;

function App() {
  const [authState, setAuthState] = useState({
    name: "",
    id: 0,
    privileges: "",
    status: false,
  });

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Container fluid className="App">
        <Router>
          <Route component={Header} />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/friend/:usertoken" exact component={FriendLogin} />
            <Route path="/newevent" exact component={NewEvent} />
            <Route path="/editevent/:id" exact component={EditEvent} />
            <Route path="/events/byId/:id" exact component={Event} />
            <Route path="/users" exact component={Users} />
            <Route path="/newuser" exact component={NewUser} />
            <Route path="/edituser/:id" exact component={EditUser} />
            <Route path="/profile/:id" exact component={Profile} />
            <Route path="/users/login" exact component={Login} />
            <Route path="/users/login/:usertoken" exact component={Login} />
            <Route path="/users/register" exact component={Register} />
            <Route path="/groups" exact component={Groups} />
            <Route path="/newgroup" exact component={NewGroup} />
            <Route path="/editgroup/:id" exact component={EditGroup} />
            <Route path="/grouptypes" exact component={GroupTypes} />
            <Route path="/newgrouptype" exact component={NewGroupType} />
            <Route path="/editgrouptype/:id" exact component={EditGroupType} />
            <Route path="/emailtemplates" exact component={EmailTemplates} />
            <Route
              path="/editemailtemplate/:id"
              exact
              component={EditEmailTemplate}
            />
            <Route
              path="/newemailtemplate"
              exact
              component={NewEmailTemplate}
            />
            <Route
              path="/sendinvitemail/:id"
              exact
              component={SendInviteMail}
            />
            <Route
              path="/sendparticipantsemail/:id"
              exact
              component={SendParticipantsEmail}
            />
            <Route path="/sendemail" exact component={SendEmail} />
          </Switch>
          <Route component={Footer} />
        </Router>
      </Container>
    </AuthContext.Provider>
  );
}

export default App;
