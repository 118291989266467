import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "@restart/ui/esm/Button";
const { REACT_APP_API_URL } = process.env;

function EmailTemplates(props) {
  let history = useHistory();
  const [listOfEmailTemplates, setListOfEmailTemplates] = useState([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);

  const { authState, setAuthState } = useContext(AuthContext);

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          if (props.location.state !== undefined) {
            if (props.location.state.reason) {
              //Show your alert here
              setShowAlert(true);
              handleVisible(props.location.state.reason, "success");
              history.replace();
            }
          }

          // Obtain list of email templates
          loadListOfEmailTemplates();
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  const loadListOfEmailTemplates = () => {
    // Obtain list of email templates
    axios
      .get(`${REACT_APP_API_URL}emailtemplates`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        setListOfEmailTemplates(response.data);
        setLoading(false);
      });
  };

  return (
    <>
      {showAlert && (
        <Alert show={visibleAlert} variant={variant}>
          {alertText}
        </Alert>
      )}
      {!loading && (
        <Container className="usersPage">
          <Row>
            <Col>
              <Button
                variant="success"
                onClick={() => history.push(`/newemailtemplate/`)}
              >
                {" "}
                New Email Template
              </Button>
              <Card className="eventUsers">
                <Card.Body>
                  {listOfEmailTemplates.map((value, key) => {
                    return (
                      <Card className="singleUserDetails" key={key}>
                        <Card.Body>
                          <Container>
                            <Row>
                              <Col>
                                <p className="mb-0" align="left">
                                  {value.InviteEventGroup.eventGroupName}
                                </p>
                              </Col>
                              <Col float="right">
                                <p className="mb-0" align="right">
                                  {value.type}{" "}
                                  <i
                                    className="fas fa-edit edit-user"
                                    onClick={() => {
                                      history.push(
                                        `/editemailtemplate/${value.id}`
                                      );
                                    }}
                                  ></i>
                                </p>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {loading && (
        <Container className="createEventPage">
          <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
        </Container>
      )}
    </>
  );
}

export default EmailTemplates;
