import React, { useEffect, useState } from "react";
import axios from "axios";

// React Bootstrap Components
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
const { REACT_APP_API_URL } = process.env;

function SelectBox(props) {
  const [listOfUsers, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const id = props.id;

  useEffect(() => {
    // Obtain details of the event
    axios
      .get(`${REACT_APP_API_URL}events/byId/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        const filter = response.data.groupName;
        console.log('filter', filter);
        // Obtain list of users
        axios
          .get(`${REACT_APP_API_URL}users/withFilter/${filter}`)
          .then((response) => {
            console.log(response.data.length);
            setUsers(response.data);
          });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [selectedUser]);

  const handleInputChange = (e) => {
    setSelectedUser(e.target.value);
  };

  const submit = () => {
    props.es6Function(selectedUser);
  };

  return (
    <Card className="eventUsers mt-2">
      <Card.Header>Add Participants</Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <select
              className="form-control"
              name="name"
              onChange={handleInputChange}
              defaultValue="Select User"
            >
              <option style={{ display: "none" }} value="0">
                Select User...
              </option>
              <option key="addGuest" value="addGuest">
                Add Guest...
              </option>
              <option disabled>
                &#9472;&#9472;&#9472;&#9472;&#9472;&#9472;
              </option>
              {listOfUsers &&
                listOfUsers.map((value, key) => {
                  return (
                    <option key={key} value={value.id}>
                      {value.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </Card.Body>
      <Card.Footer className="text-muted">
        <Button variant="primary" onClick={submit}>
          Add
        </Button>
      </Card.Footer>
    </Card>
  );
}

export default SelectBox;
