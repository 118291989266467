import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
const { REACT_APP_API_URL } = process.env;

function NewEvent() {
  let history = useHistory();
  const [listOfInviteEventGroups, setListOfInviteEventGroups] = useState([]);
  const [listOfGroupTypes, setListOfGroupTypes] = useState([]);
  const [groupAdmin, setGroupAdmin] = useState("");
  const { authState, setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const initialValues = {
    date: "",
    event: "",
    course: "",
    holes: "18",
    startTime: "",
    secondaryStartTime: "",
    duration: "",
    places_total: "",
    type: "",
    groupName: "",
    groupNumber: 0,
    groupType: "Flights",
  };

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          const groupAdminArray = response.data.name.split(" ");
          setGroupAdmin(groupAdminArray[0]);
          if (groupAdminArray[0] === "Administrator") {
            // Obtain list of groups
            axios
              .get(`${REACT_APP_API_URL}inviteeventgroups`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((response) => {
                setListOfInviteEventGroups(response.data);
                console.log(listOfInviteEventGroups); //Temporary
                // Obtain list of groups types
                axios
                  .get(`${REACT_APP_API_URL}grouptypes`, {
                    headers: {
                      accessToken: localStorage.getItem("accessToken"),
                    },
                  })
                  .then((response) => {
                    setListOfGroupTypes(response.data);
                    setLoading(false);
                  });
              });
          } else {
            // Obtain InviteEventGroupId from groupName
            axios
              .get(
                `${REACT_APP_API_URL}inviteeventgroups/byname/${groupAdminArray[0]}`,
                {
                  headers: {
                    accessToken: localStorage.getItem("accessToken"),
                  },
                }
              )
              .then((response) => {
                let arr = [];
                arr.push(response.data);
                setListOfInviteEventGroups(arr);

                console.log(listOfInviteEventGroups); //Temporary
                // Obtain list of groups types
                axios
                  .get(`${REACT_APP_API_URL}grouptypes`, {
                    headers: {
                      accessToken: localStorage.getItem("accessToken"),
                    },
                  })
                  .then((response) => {
                    setListOfGroupTypes(response.data);
                    setLoading(false);
                  });
              });
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const validationSchema = Yup.object().shape({
    date: Yup.date()
      .required("You must input a date")
      .default(today)
      .min(today, "Date cannot be in the past"),
    event: Yup.string().required("Required"),
    course: Yup.string().required("Required"),
    startTime: Yup.string().required("Required"),
    places_total: Yup.number().min(1, "Minimum 1 spot"),
    type: Yup.string().required("Required"),
    //groupName: Yup.string().required('Required'),
    groupType: Yup.string().required("Required"),
  });

  const onSubmit = (formData) => {
    if (groupAdmin !== "Administrator") {
      formData.groupName = groupAdmin;
    } else {
      if (formData.groupName === "") {
        formData.groupName = "All";
      }
    }

    const data = {
      date: formData.date,
      event: formData.event,
      course: formData.course,
      holes: formData.holes,
      startTime: formData.startTime,
      secondaryStartTime: formData.secondaryStartTime,
      duration: formData.duration,
      places_total: formData.places_total,
      type: formData.type,
      groupNumber: 0,
      groupName: formData.groupName,
      groupType: formData.groupType,
    };

    //Obtain Group Number
    axios
      .get(`${REACT_APP_API_URL}inviteeventgroups/byname/${data.groupName}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        data.groupNumber = response.data.groupNumber;
        axios
          .post(`${REACT_APP_API_URL}events/`, data, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
            history.push("/");
          })
          .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
          });
      });
  };

  return (
    <Container className="createEventPage">
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form className="formContainer">
            <label>Group Name</label>
            <ErrorMessage name="groupName" component="span" />
            {groupAdmin === "Administrator" && (
              <Field as="select" id="inputCreateEvent" name="groupName">
                {listOfInviteEventGroups.map((value, key) => {
                  return (
                    <option value={value.eventGroupName}>
                      {value.eventGroupName}
                    </option>
                  );
                })}
              </Field>
            )}
            {groupAdmin !== "Administrator" && (
              <Field
                id="inputCreateEvent"
                name="groupName"
                value={groupAdmin}
                disabled
              />
            )}
            <label>Date</label>
            <ErrorMessage name="date" component="span" />
            <Field
              id="inputCreateEvent"
              name="date"
              placeholder="(Ex. Title...)"
              type="date"
            />
            <label>Event</label>
            <ErrorMessage name="event" component="span" />
            <Field
              id="inputCreateEvent"
              name="event"
              placeholder="(Golf Day...)"
            />
            <label>Course</label>
            <ErrorMessage name="course" component="span" />
            <Field
              id="inputCreateEvent"
              name="course"
              placeholder="(My Golf Course...)"
            />
            <label>Start Time</label>
            <ErrorMessage name="startTime" component="span" />
            <Field id="inputCreateEvent" name="startTime" type="time" />
            <label>Secondary Start Time</label>
            <ErrorMessage name="secondaryStartTime" component="span" />
            <Field
              id="inputCreateEvent"
              name="secondaryStartTime"
              type="time"
            />
            <label>Total Spots</label>
            <ErrorMessage name="places_total" component="span" />
            <Field
              id="inputCreateEvent"
              name="places_total"
              placeholder="(Ex. 24...)"
              type="number"
            />
            <label>Type</label>
            <ErrorMessage name="type" component="span" />
            <Field id="inputCreateEvent" name="type" placeholder="(Golf...)" />
            <label>Group Type</label>
            <ErrorMessage name="groupType" component="span" />
            <Field as="select" id="inputCreateEvent" name="groupType">
              {listOfGroupTypes.map((value, key) => {
                return (
                  <option value={value.groupName}>{value.groupName}</option>
                );
              })}
            </Field>
            <Button variant="success" type="submit">
              Create Event
            </Button>
          </Form>
        </Formik>
      )}
      {loading && (
        <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
      )}
    </Container>
  );
}

export default NewEvent;
