import axios from 'axios';
import React, { useEffect, useState } from 'react';
const { REACT_APP_API_URL } = process.env;

export default function EventHcpEdit({ user }) {
    // const variables
    const hcp = (user.guest === 1) ? user.Guest.handicap : user.User.handicap;
    const inputStyle = {
        maxWidth: '55px'
    }

    // useState variables
    const [value, setValue] = useState(hcp);
    const [processing, setProcessing] = useState(false);

    // functions
    function changeHandler(e) {
        const value = e.target.value;
        
        if (value === '' || value === null) return ; // check if input value is empty or not
        if (/[a-zA-Z]/g.test(value)) return ; // check if string contains any letters

        setValue(value);
    }

    function editHCP(value){
        setProcessing(true);

        axios.put(`${REACT_APP_API_URL}users/edithcp`,
        {
            guest: user.guest,
            userId: (user.guest === 1) ? user.GuestId : user.UserId,
            hcp: value,
        },
        {
            headers: {
                accessToken: localStorage.getItem("accessToken"),
            },
        }
        ).then((res) => {
            console.log(res.data);
            setProcessing(false);
        });
    }

    // useEffects
    // Use effect to detect for when user stops writing, so we can send a request to the backend
    useEffect(() => {
        // check, if current value is the same as start value, then do nothing
        if (value === hcp) return ;

        const timeoutID = setTimeout(() => {
            editHCP(value);
        }, 2000);
        return () => clearTimeout(timeoutID);
    }, [value]);

    return (
        <td>
            <input disabled={processing} value={value} style={inputStyle} onChange={changeHandler} />
        </td>
    )
}
