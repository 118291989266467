import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../helpers/AuthContext";
import { useHistory } from "react-router-dom";
const { REACT_APP_API_URL } = process.env;

function FriendLogin() {
  let history = useHistory();
  let { usertoken } = useParams();
  const { setAuthState } = useContext(AuthContext);

  useEffect(() => {
    const data = { usertoken };
    console.log(data);
    axios
      .post(`${REACT_APP_API_URL}users/friendlogin`, data)
      .then((response) => {
        console.log(response);
        if (response.status >= 200 && response.status <= 299) {
          localStorage.setItem("accessToken", response.data);
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          history.push("/");
        } else {
          history.push(`/users/login/${usertoken}`);
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        history.push(`/users/login/${usertoken}`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
}

export default FriendLogin;
