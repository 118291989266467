import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { useHistory } from "react-router-dom";
import axios from "axios";
import UserInformationHeader from "./UserInformationHeader";

// React Bootstrap Components
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
const { REACT_APP_API_URL } = process.env;

function Header() {
  const { authState, setAuthState } = useContext(AuthContext);
  let history = useHistory();

  const logout = () => {
    localStorage.removeItem("accessToken");
    setAuthState(false);
    history.push(`/users/login`);
  };

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <header className="inner">
            <Navbar variant="light" expand="xxl">
              <Navbar.Brand href="/">
                <img
                  src="https://elparaisoeventreg.scala4.com/logo.webp"
                  alt="El Paraiso Golf Club Logo"
                  id="logo"
                  type="image/webp"
                />
                <h2>
                  <Link to="/">
                    <span className="app_name"> Event Registration</span>
                  </Link>
                </h2>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav className="mr-auto">
                  {!authState.status && (
                    <>
                      <Nav.Link href="/users/register" className="header-link">
                        Register
                      </Nav.Link>
                      <Nav.Link href="/users/login" className="header-link">
                        Login
                      </Nav.Link>
                    </>
                  )}
                  {authState.status && (
                    <>
                      <Navbar.Text className="header-text">
                        Signed in as: {authState.name} ({authState.privileges}){" "}
                        <i
                          className="fas fa-edit edit-user profileLink"
                          onClick={() => {
                            history.push(`/profile/${authState.id}`);
                          }}
                        ></i>
                      </Navbar.Text>
                      <Nav.Link href="/" className="header-link">
                        Home
                      </Nav.Link>
                    </>
                  )}
                  {authState.status && authState.privileges === "Admin" && (
                    <>
                      <Nav.Link href="/newevent" className="header-link">
                        New Event
                      </Nav.Link>
                      <Nav.Link href="/users" className="header-link">
                        Users
                      </Nav.Link>
                      <NavDropdown
                        title={
                          <span>
                            <i className="fas fa-cog"></i>
                          </span>
                        }
                        id="basic-nav-dropdown"
                        className="header-link"
                      >
                        <NavDropdown.Item href="/groups">
                          Groups
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/grouptypes">
                          Group Types
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/emailtemplates">
                          Email Templates
                        </NavDropdown.Item>
                      </NavDropdown>
                        <a href="https://mail.scala4.com:2096/" target="_blank" rel="noreferrer">
                            <img src={process.env.PUBLIC_URL + '/EPMail.jpg'}
                            width="50"
                            height="60"
                            alt="El Paraiso mail"
                            />
                        </a>
                    </>
                  )}
                  {authState.status && (
                    <Button variant="outline-success" onClick={logout}>
                      Logout
                    </Button>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            {authState.status && <UserInformationHeader />}
            {/* <div className='header-title'>
              <img
                src='https://elparaisoeventreg.scala4.com/logo.webp'
                alt='El Paraiso Golf Club Logo'
                id='logo'
                type='image/webp'
              />
              <h2>
                <Link to='/'>
                  <i className='fas fa-golf-ball'></i>
                  <span className='app_name'> Event Registration</span>
                </Link>
              </h2>
            </div>

            <Navbar expand='lg'>
              <Container>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav'>
                  <Nav className='justify-content-end' fill variant='pills'>
                    {!authState.status && (
                      <>
                        <Nav.Link href='/users/register'>Register</Nav.Link>
                        <Nav.Link href='/users/login'>Login</Nav.Link>
                      </>
                    )}
                    {authState.status && (
                      <>
                        <Navbar.Text>
                          <strong>
                            Signed in as: {authState.name} (
                            {authState.privileges}){' '}
                            <i
                              className='fas fa-edit edit-user profileLink'
                              onClick={() => {
                                history.push(`/profile/${authState.id}`);
                              }}
                            ></i>
                          </strong>
                        </Navbar.Text>
                        <Nav.Link href='/'>Home</Nav.Link>
                      </>
                    )}
                    {authState.status && authState.privileges === 'Admin' && (
                      <>
                        <Nav.Link href='/newevent'>New Event</Nav.Link>
                        <Nav.Link href='/users'>Manage Users</Nav.Link>
                        <Nav.Link href='/sendemail'>Send Email</Nav.Link>
                      </>
                    )}

                    {authState.status && (
                      <Button variant='outline-success' onClick={logout}>
                        Logout
                      </Button>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>

            <div className='header-title-small-screen'>
              <img
                src='https://elparaisoeventreg.scala4.com/logo.webp'
                alt='El Paraiso Golf Club Logo'
                type='image/webp'
              />
            </div> */}
          </header>
        </div>
      </div>
    </div>
  );
}

export default Header;
