import React, { useState } from "react";
import axios from "axios";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
const { REACT_APP_API_URL } = process.env;

function Register() {
  const [memberId, setNewMemberId] = useState("");
  const [name, setNewName] = useState("");
  const [email, setNewEmail] = useState("");
  const [password, setNewPassword] = useState("");
  const [phone, setNewPhone] = useState("");
  const [handicap, setNewHandicap] = useState("");
  const [federation, setNewFederation] = useState("");
  const privileges = "User";
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  const addUser = () => {
    const handicapWithComma = handicap.replace(".", ",");
    const data = {
      memberId,
      name,
      email,
      password,
      phone,
      handicap: handicapWithComma,
      federation,
      privileges,
    };
    axios
      .post(`${REACT_APP_API_URL}users/`, data)
      .then((response) => {
        handleVisible("User Created", "success");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        handleVisible(error.response.data.msg, "danger");
      });
  };

  return (
    <>
      <Alert show={visibleAlert} variant={variant}>
        {alertText}
      </Alert>
      <Container className="login-register-pages">
        <Row>
          <Col>
            <Form className="formContainer">
              <input
                type="number"
                placeholder="Member Id..."
                autoComplete="off"
                value={memberId}
                id="inputCreateUser"
                onChange={(event) => {
                  setNewMemberId(event.target.value);
                }}
              />
              <input
                type="text"
                placeholder="Name..."
                autoComplete="off"
                value={name}
                id="inputCreateUser"
                onChange={(event) => {
                  setNewName(event.target.value);
                }}
              />
              <input
                type="email"
                placeholder="Email..."
                autoComplete="off"
                value={email}
                id="inputCreateUser"
                onChange={(event) => {
                  setNewEmail(event.target.value);
                }}
              />
              <input
                type="password"
                placeholder="Password..."
                autoComplete="off"
                value={password}
                id="inputCreateUser"
                onChange={(event) => {
                  setNewPassword(event.target.value);
                }}
              />
              <input
                type="text"
                placeholder="Phone..."
                autoComplete="off"
                value={phone}
                id="inputCreateUser"
                onChange={(event) => {
                  setNewPhone(event.target.value);
                }}
              />
              <input
                type="text"
                placeholder="Handicap..."
                autoComplete="off"
                value={handicap}
                id="inputCreateUser"
                onChange={(event) => {
                  setNewHandicap(event.target.value);
                }}
              />
              <input
                type="text"
                placeholder="Federation..."
                autoComplete="off"
                value={federation}
                id="inputCreateUser"
                onChange={(event) => {
                  setNewFederation(event.target.value);
                }}
              />
              <Button variant="success" onClick={addUser}>
                {" "}
                Register
              </Button>
            </Form>
          </Col>
        </Row>
        <Row>
          <p>
            Already registered? Please login{" "}
            <a
              href="https://elparaisoeventreg.scala4.com/users/login"
              className="subtext-link"
            >
              here
            </a>
          </p>
        </Row>
      </Container>
    </>
  );
}

export default Register;
