import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { AuthContext } from "../helpers/AuthContext";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
const { REACT_APP_API_URL } = process.env;

const baseStyle = {
  alignItems: "center",
  borderStyle: "dashed",
  borderWidth: "2px",
  borderRadius: "20px",
  borderColor: "rgb(204, 204, 204)",
  display: "flex",
  flexDirection: "column",
  height: "140px",
  justifyContent: "center",
  padding: "20px",
  marginTop: "30px",
  marginBottom: "30px",
  cursor: "default",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function EditGroup() {
  let { id } = useParams();
  let history = useHistory();

  const [value, setValue] = useState();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");
  const [items, setItems] = useState([]);

  const { authState, setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const initialValue = useRef({
    groupNumber: "",
    eventGroupName: "",
  });

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          // Obtain details of the group
          axios
            .get(`${REACT_APP_API_URL}inviteeventgroups/byid/${id}`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((response) => {
              //setEventObject(response.data);

              initialValue.current.groupNumber = response.data.groupNumber;
              initialValue.current.eventGroupName =
                response.data.eventGroupName;

              setLoading(false);

              setValue({});
              console.log(value);
            });
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  const validationSchema = Yup.object().shape({
    groupNumber: Yup.number().min(1, "Minimum 1 spot"),
    eventGroupName: Yup.string().required("Required"),
  });

  const onSubmit = (data) => {
    axios
      .put(`${REACT_APP_API_URL}inviteeventgroups/${id}`, data, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        window.scrollTo(0, 0);
        handleVisible("Changes Saved", "success");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        window.scrollTo(0, 0);
        handleVisible(error.response.data.msg, "danger");
      });
  };

  const deleteEvent = () => {
    axios
      .delete(`${REACT_APP_API_URL}inviteeventgroups/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        history.push(`/groups`);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        window.scrollTo(0, 0);
        handleVisible(error.response.data.msg, "danger");
      });
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;

          const wb = XLSX.read(bufferArray, { type: "buffer" });

          const wsname = wb.SheetNames[0];

          const ws = wb.Sheets[wsname];

          const data = XLSX.utils.sheet_to_json(ws);

          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });

      promise.then((d) => {
        setItems(d);
      });
    });
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    let federation = "";
    // Cycle through data
    items.map(async (participant) => {
      federation = participant["Licencia"];
      await axios
        .get(`${REACT_APP_API_URL}users/byfederation/${federation}`, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then(async (response) => {
          const userId = response.data.id;
          // Obtain current status on invitegrouplists
          await axios
            .get(`${REACT_APP_API_URL}invitegrouplists/${userId}`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then(async (response) => {
              let group1 = response.data[0].group1;
              let group2 = response.data[0].group2;
              let group3 = response.data[0].group3;
              let group4 = response.data[0].group4;
              let group5 = response.data[0].group5;
              let group6 = response.data[0].group6;
              let group7 = response.data[0].group7;
              let group8 = response.data[0].group8;
              let group9 = response.data[0].group9;
              let group10 = response.data[0].group10;
              // Obtain groupnumber to change
              let inviteGroupListsData = obtainVariable(
                group1,
                group2,
                group3,
                group4,
                group5,
                group6,
                group7,
                group8,
                group9,
                group10,
                initialValue.current.groupNumber
              );

              // Add register to invitegrouplists table
              await axios
                .put(
                  `${REACT_APP_API_URL}invitegrouplists/${userId}`,
                  { inviteGroupListsData },
                  {
                    headers: {
                      accessToken: localStorage.getItem("accessToken"),
                    },
                  }
                )
                .then((response) => {
                  //history.push('/users');
                })
                .catch(function (error) {
                  if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                  }
                  window.scrollTo(0, 0);
                  handleVisible(error.response.data.msg, "danger");
                });
            })
            .catch(function (error) {
              console.log(error);
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            });
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const obtainVariable = (
    group1,
    group2,
    group3,
    group4,
    group5,
    group6,
    group7,
    group8,
    group9,
    group10,
    value
  ) => {
    switch (value) {
      case 1:
        return {
          group1: 1,
          group2,
          group3,
          group4,
          group5,
          group6,
          group7,
          group8,
          group9,
          group10,
        };
      case 2:
        return {
          group1,
          group2: 1,
          group3,
          group4,
          group5,
          group6,
          group7,
          group8,
          group9,
          group10,
        };
      case 3:
        return {
          group1,
          group2,
          group3: 1,
          group4,
          group5,
          group6,
          group7,
          group8,
          group9,
          group10,
        };
      case 4:
        return {
          group1,
          group2,
          group3,
          group4: 1,
          group5,
          group6,
          group7,
          group8,
          group9,
          group10,
        };
      case 5:
        return {
          group1,
          group2,
          group3,
          group4,
          group5: 1,
          group6,
          group7,
          group8,
          group9,
          group10,
        };
      case 6:
        return {
          group1,
          group2,
          group3,
          group4,
          group5,
          group6: 1,
          group7,
          group8,
          group9,
          group10,
        };
      case 7:
        return {
          group1,
          group2,
          group3,
          group4,
          group5,
          group6,
          group7: 1,
          group8,
          group9,
          group10,
        };
      case 8:
        return {
          group1,
          group2,
          group3,
          group4,
          group5,
          group6,
          group7,
          group8: 1,
          group9,
          group10,
        };
      case 9:
        return {
          group1,
          group2,
          group3,
          group4,
          group5,
          group6,
          group7,
          group8,
          group9: 1,
          group10,
        };
      case 10:
        return {
          group1,
          group2,
          group3,
          group4,
          group5,
          group6,
          group7,
          group8,
          group9,
          group10: 1,
        };
      default:
        console.log(`Group out of scope`);
    }
  };

  return (
    <>
      <Alert show={visibleAlert} variant={variant}>
        {alertText}
      </Alert>
      {!loading && (
        <Container className="createEventPage">
          <Formik
            initialValues={initialValue.current}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form className="formContainer">
              <label>Group Number</label>
              <ErrorMessage name="groupNumber" component="span" />
              <Field id="inputCreateEvent" name="groupNumber" type="number" />
              <label>Group Name</label>
              <ErrorMessage name="eventGroupName" component="span" />
              <Field id="inputCreateEvent" name="eventGroupName" />
              <Button variant="success" type="submit">
                Save Event
              </Button>
              <Button variant="danger" onClick={deleteEvent}>
                Delete Event
              </Button>
            </Form>
          </Formik>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>
              Drop XLS file of users that should belong to this group (format:
              Federation Number on each line)
            </p>
          </div>
        </Container>
      )}
      {loading && (
        <Container className="createEventPage">
          <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
        </Container>
      )}
    </>
  );
}

export default EditGroup;
