import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
const { REACT_APP_API_URL } = process.env;

function NewGroup() {
  let history = useHistory();

  const [newGroupNumber, setNewGroupNumber] = useState("");
  const [newEventGroupName, setNewEventGroupName] = useState("");
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");
  const { authState, setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          // Obtain last group number
          axios
            .get(`${REACT_APP_API_URL}inviteeventgroups/lastgroupnumber`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((response) => {
              console.log(response.data.groupNumber);
              // if (response.data.groupNumber === 10) {
              //   handleVisible("Impossible to create more groups!", "danger");
              //   setDisabled(true);
              // } else {
              // }
              
              setNewGroupNumber(response.data.groupNumber + 1);
              setLoading(false);
            });

          window.scrollTo(0, 0);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  const addGroup = () => {
    const data = {
      groupNumber: newGroupNumber,
      eventGroupName: newEventGroupName,
    };
    axios
      .post(`${REACT_APP_API_URL}inviteeventgroups/`, data, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        setNewGroupNumber("");
        setNewEventGroupName("");
        // window.scrollTo(0, 0);
        // handleVisible('Group Saved', 'success');
        history.push(`/groups`);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        window.scrollTo(0, 0);
        handleVisible(error.response.data.msg, "danger");
      });
  };

  return (
    <>
      <Alert show={visibleAlert} variant={variant}>
        {alertText}
      </Alert>
      {!loading && (
        <Container className="createUserPage">
          <Row>
            <Col>
              <Form className="formContainer">
                <label for="memberid">Group Number</label>
                <input
                  type="number"
                  placeholder="Group Number..."
                  autoComplete="off"
                  name="groupnumber"
                  value={newGroupNumber}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewGroupNumber(event.target.value);
                  }}
                  disabled
                />
                <label for="name">Group Name</label>
                <input
                  type="text"
                  placeholder="Name..."
                  autoComplete="off"
                  name="name"
                  value={newEventGroupName}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewEventGroupName(event.target.value);
                  }}
                />
                {!disabled && (
                  <Button
                    variant="success"
                    ClassName="regular-button"
                    onClick={() => addGroup()}
                  >
                    {" "}
                    Add
                  </Button>
                )}
                {disabled && (
                  <Button variant="success" ClassName="regular-button" disabled>
                    {" "}
                    Add
                  </Button>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      )}
      {loading && (
        <Container className="createEventPage">
          <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
        </Container>
      )}
    </>
  );
}

export default NewGroup;
