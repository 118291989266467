import React, { useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
const { REACT_APP_API_URL } = process.env;

function EditEmailTemplate() {
  let { id } = useParams();
  let history = useHistory();

  const [value, setValue] = useState();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");
  const [loading, setLoading] = useState(true);

  const { authState, setAuthState } = useContext(AuthContext);

  const initialValue = useRef({
    eventGroupName: "",
    type: "",
    subject: "",
    body: "",
  });

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          // Obtain details of the email template
          axios
            .get(`${REACT_APP_API_URL}emailtemplates/byid/${id}`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((response) => {
              //setEventObject(response.data);

              console.log(response.data);

              initialValue.current.eventGroupName =
                response.data[0].InviteEventGroup.eventGroupName;
              initialValue.current.type = response.data[0].type;
              initialValue.current.subject = response.data[0].subject;
              initialValue.current.body = response.data[0].body;

              setLoading(false);

              setValue({});
              console.log(value);
            });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Required"),
    subject: Yup.string().required("Required"),
    body: Yup.string().required("Required"),
  });

  const onSubmit = (data) => {
    axios
      .put(`${REACT_APP_API_URL}emailtemplates/${id}`, data, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        window.scrollTo(0, 0);
        handleVisible("Changes Saved", "success");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        window.scrollTo(0, 0);
        handleVisible(error.response.data.msg, "danger");
      });
  };

  const deleteEmailTemplate = () => {
    axios
      .delete(`${REACT_APP_API_URL}emailtemplates/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        history.push(`/emailtemplates`);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        window.scrollTo(0, 0);
        handleVisible(error.response.data.msg, "danger");
      });
  };

  return (
    <>
      <Alert show={visibleAlert} variant={variant}>
        {alertText}
      </Alert>
      {!loading && (
        <Container className="createEventPage">
          <Formik
            initialValues={initialValue.current}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form className="formContainer">
              <label>Group Name</label>
              <ErrorMessage name="eventGroupName" component="span" />
              <Field id="inputCreateEvent" name="eventGroupName" disabled />
              <label>Type</label>
              <ErrorMessage name="type" component="span" />
              <Field id="inputCreateEvent" name="type" />
              <label>Subject</label>
              <ErrorMessage name="subject" component="span" />
              <Field id="inputCreateEvent" name="subject" />
              <label>Body</label>
              <ErrorMessage name="body" component="span" />
              <Field id="inputCreateEvent" name="body" />
              <Button variant="success" type="submit">
                Save Email Template
              </Button>
              <Button variant="danger" onClick={deleteEmailTemplate}>
                Delete EmailTemplate
              </Button>
            </Form>
          </Formik>
        </Container>
      )}
      {loading && (
        <Container className="createEventPage">
          <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
        </Container>
      )}
    </>
  );
}

export default EditEmailTemplate;
