import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
const { REACT_APP_API_URL } = process.env;

function UserInformationHeader(props) {
  const [email, setEmail] = useState("");
  const [handicap, setHandicap] = useState("");
  const [federation, setFederation] = useState("");
  const [balance, setBalance] = useState("");
  const [slopeValues, setSlopeValues] = useState("");
  const [loading, setLoading] = useState(true);
  const { authState, setAuthState } = useContext(AuthContext);
  let history = useHistory();

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          // Obtain user Data
          axios
            .get(`${REACT_APP_API_URL}users/byId/${response.data.id}`)
            .then((response) => {
              setEmail(response.data.email);
              setHandicap(response.data.handicap);
              setFederation(response.data.federation);
              if (response.data.balanceDate !== null) {
                if (response.data.balanceDate < new Date()) {
                  console.log(response.data.balanceDate);
                }
              }
              // Obtain Slope Values
              if (response.data.gender === "F") {
                axios
                  .get(
                    `${REACT_APP_API_URL}slopewomen/${response.data.handicap}`,
                    {
                      headers: {
                        accessToken: localStorage.getItem("accessToken"),
                      },
                    }
                  )
                  .then((res) => {
                    setSlopeValues(res.data);
                    // Obtain balance
                    axios
                      .get(
                        `${REACT_APP_API_URL}webscraping/${response.data.id}`
                      )
                      .then((response) => {
                        setBalance(response.data);
                        setLoading(false);
                      })
                      .catch(function (error) {
                        setBalance("NA");
                        setLoading(false);
                      });
                  });
              } else {
                axios
                  .get(
                    `${REACT_APP_API_URL}slopemen/${response.data.handicap}`,
                    {
                      headers: {
                        accessToken: localStorage.getItem("accessToken"),
                      },
                    }
                  )
                  .then((res) => {
                    setSlopeValues(res.data);
                    // Obtain balance
                    axios
                      .get(
                        `${REACT_APP_API_URL}webscraping/${response.data.id}`
                      )
                      .then((response) => {
                        setBalance(response.data);
                        setLoading(false);
                      })
                      .catch(function (error) {
                        setBalance("NA");
                        setLoading(false);
                      });
                  });
              }
            });
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="pt-1">
      <Card className="personal-information">
        <Card.Body className="userinformationheader-body">
          <Card.Text>
            HCP: <strong>{handicap}</strong>
          </Card.Text>
          <Card.Text>
            Federation Number: <strong>{federation}</strong>
          </Card.Text>
          <Card.Text>
            Email: <strong>{email}</strong>
          </Card.Text>
          {!loading && (
            <>
              <Form.Group className="form-flex">
                <Form.Label>Balance: </Form.Label>
                <Form.Control placeholder={balance} disabled />
                <p> €</p>
              </Form.Group>
            </>
          )}
          {loading && (
            <>
              <Form.Group className="form-flex">
                <Form.Label>Balance: </Form.Label>
                <Oval
                  color="#0c7b49"
                  height={24}
                  width={24}
                  ariaLabel="loading"
                />
              </Form.Group>
            </>
          )}
        </Card.Body>
        <Card.Body className="p-0 text-center">
          <Card.Text>
            <strong>Slope</strong> 100%,90%,85% - <strong>60</strong>{" "}
            {slopeValues[0]},{slopeValues[1]},{slopeValues[2]} -{" "}
            <strong>56</strong> {slopeValues[3]},{slopeValues[4]},
            {slopeValues[5]} - <strong>52</strong> {slopeValues[6]},
            {slopeValues[7]},{slopeValues[8]} - <strong>49</strong>{" "}
            {slopeValues[9]},{slopeValues[10]},{slopeValues[11]} -{" "}
            <strong>45</strong> {slopeValues[12]},{slopeValues[13]},
            {slopeValues[14]}
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default UserInformationHeader;
