import React, { useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AuthContext } from "../helpers/AuthContext";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
const { REACT_APP_API_URL } = process.env;

function SendParticipantsEmail() {
  let { id } = useParams();

  const [eventObject, setEventObject] = useState([]);
  const [listOfEventUsers, setEventUsers] = useState([]);
  const [body, setBody] = useState();
  const [subject, setSubject] = useState();
  const [attachmentList, setAttachmentList] = useState([]);

  const [sent, setSent] = useState(false);

  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");
  const { authState, setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  let history = useHistory();

  const initialValue = useRef({
    subject: "",
    body: "",
  });

  const attachment = "";
  //const attachmentArray = [];

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          // Obtain details of the event
          axios
            .get(`${REACT_APP_API_URL}events/byId/${id}`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((response) => {
              setEventObject(response.data);
              const event = response.data;

              // Obtain InviteEventGroupId from groupName
              axios
                .get(
                  `${REACT_APP_API_URL}inviteeventgroups/byname/${event.groupName}`,
                  {
                    headers: {
                      accessToken: localStorage.getItem("accessToken"),
                    },
                  }
                )
                .then((response) => {
                  const inviteEventGroupId = response.data.id;
                  // Obtain template data
                  const templateType = "Inform";
                  axios
                    .get(
                      `${REACT_APP_API_URL}emailtemplates/byGroupNumber/${inviteEventGroupId}&${templateType}`,
                      {
                        headers: {
                          accessToken: localStorage.getItem("accessToken"),
                        },
                      }
                    )
                    .then((response) => {
                      const templateData = response.data;

                      // Obtain participants enrolled in the event
                      axios
                        .get(`${REACT_APP_API_URL}eventusers/${id}`, {
                          headers: {
                            accessToken: localStorage.getItem("accessToken"),
                          },
                        })
                        .then((response) => {
                          setEventUsers(response.data);

                          const array = [response.data];
                          let time = event.startTime;
                          let newStartTime = new Date(
                            new Date("1970/01/01 " + time).getTime()
                          ).toLocaleTimeString("en-UK", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          });
                          let newFlight = array[0][0].flight;
                          let newTee = array[0][0].tee;
                          let minsToAdd = 9;
                          let participantsList = "";
                          let totalHcp = 0;
                          array[0].map((item) => {
                            if (newTee !== item.tee) {
                              if (event.secondaryStartTime !== null) {
                                participantsList += `Group HCP: ${totalHcp
                                  .toFixed(1)
                                  .toString()
                                  .replace(".", ",")}`;
                                totalHcp = 0;
                                newTee = item.tee;
                                newFlight = item.flight;
                                time = event.secondaryStartTime;
                                newStartTime = new Date(
                                  new Date("1970/01/01 " + time).getTime()
                                ).toLocaleTimeString("en-UK", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                });
                                participantsList += `</ul><br /><ul>`;
                              } else {
                                participantsList += `Group HCP: ${totalHcp
                                  .toFixed(1)
                                  .toString()
                                  .replace(".", ",")}`;
                                totalHcp = 0;
                                newTee = item.tee;
                                newFlight = item.flight;
                                time = event.startTime;
                                newStartTime = new Date(
                                  new Date("1970/01/01 " + time).getTime()
                                ).toLocaleTimeString("en-UK", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                });
                                participantsList += `</ul><br /><ul>`;
                              }
                            }
                            if (newFlight !== item.flight) {
                              participantsList += `Group HCP: ${totalHcp
                                .toFixed(1)
                                .toString()
                                .replace(".", ",")}`;
                              totalHcp = 0;
                              newFlight = item.flight;
                              time = newStartTime;
                              newStartTime = new Date(
                                new Date("1970/01/01 " + time).getTime() +
                                  minsToAdd * 60000
                              ).toLocaleTimeString("en-UK", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              });
                              participantsList += `</ul><br /><ul>`;
                            }
                            if (item.User) {
                              participantsList += `<li>${newStartTime} - ${item.tee} - ${item.User.name} - ${item.User.handicap}`;
                              totalHcp += parseFloat(
                                item.User.handicap.replace(/,/, ".")
                              );
                            } else {
                              participantsList += `<li>${newStartTime} - ${item.tee} - ${item.Guest.name} - ${item.Guest.handicap}`;
                              totalHcp += parseFloat(
                                item.Guest.handicap.replace(/,/, ".")
                              );
                            }
                            if (item.observations) {
                              participantsList += ` - ${item.observations}`;
                            }
                            participantsList += `</li>`;
                            return {
                              startTime: newStartTime,
                            };
                          });
                          participantsList += `Group HCP: ${totalHcp
                            .toFixed(1)
                            .toString()
                            .replace(".", ",")}`;

                          initialValue.current.subject = `${templateData[0].subject} - Event ${event.event}`;
                          initialValue.current.body = `<h3>${
                            templateData[0].body
                          }</h3>
                            <p>You are enroled in the following event:</p>
                            <ul>  
                              <li>Start: ${String(event.startTime).slice(
                                0,
                                5
                              )}</li>
                              <li>Event: ${event.event}</li>
                              <li>Date: ${new Date(
                                event.date
                              ).toLocaleDateString()}</li>
                              <li>Link: <a href="https://elparaisoeventreg.scala4.com/events/byId/${id}">https://elparaisoeventreg.scala4.com/events/byId/${id}</a></li>
                            </ul>
                            <ul>
                              <p> </p>`;

                          initialValue.current.body =
                            initialValue.current.body.concat(participantsList);

                          initialValue.current.body =
                            initialValue.current.body.concat("</ul>");

                          setBody(initialValue.current.body);
                          setSubject(initialValue.current.subject);
                          setLoading(false);
                        });
                    });
                });
            });
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [body, setBody]);

  useEffect(() => {}, [subject, setSubject]);

  useEffect(() => {
  }, [attachmentList, setAttachmentList]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventObject, setEventObject]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfEventUsers, setEventUsers]);

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  var execTest = function (str) {
    return str.replace(/^.*[\\/]/, "");
  };

  const addAttachment = (value) => {
    //console.log('attachmentArray before', attachmentArray);
    const filename = execTest(value);
    const path = value;
    const newAttachment = {
      id: attachmentList.length + 1,
      filename,
      path,
    };
    //attachmentArray.push(newAttachment);
    //console.log('attachmentArray after', attachmentArray);
    setAttachmentList((oldList) => [...oldList, newAttachment]);
  };

  const submit = () => {
    const sender = authState.id;
    const message = `${eventObject.event} - ${new Date(
      eventObject.date
    ).toLocaleDateString()} - https://elparaisoeventreg.scala4.com/events/byId/${id}`;
    const participants = listOfEventUsers;
    const groupName = eventObject.groupName;

    const output = body;
    const attachments = attachmentList;

    // console.log('attachmentsList', attachmentList);

    axios
      .post(
        `${REACT_APP_API_URL}mail/sendparticipantsmail`,
        {
          subject,
          message,
          output,
          participants,
          sender,
          attachments,
          groupName,
        },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((response) => {
        setSent(true);
        handleVisible("Emails Sent", "success");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        handleVisible(error.response.data.msg, "danger");
      });
  };

  return (
    <>
      <Alert show={visibleAlert} variant={variant}>
        {alertText}
      </Alert>
      {!loading && (
        <Container className="sendInviteMail">
          <Form className="formContainer">
            <label for="subject">Subject</label>
            <input
              type="text"
              autoComplete="off"
              name="subject"
              value={subject}
              id="inputCreateUser"
              onChange={(event) => {
                setSubject(event.target.value);
              }}
            />
            <label>Body</label>
            <ReactQuill theme="snow" value={body || ""} onChange={setBody} />
            <label for="attachment">Attachment</label>
            <input
              type="text"
              autoComplete="off"
              name="attachment"
              value={attachment}
              id="inputCreateUser"
              onChange={(event) => {
                addAttachment(event.target.value);
              }}
            />
            {attachmentList.length > 0 && (
              <div>
                {attachmentList.map((value, key) => {
                  return <p>{`${attachmentList[key].filename}`}</p>;
                })}
              </div>
            )}
            {attachmentList.length > 0 && (
              <div>{`${attachmentList[0].filename}`}</div>
            )}
            {!sent && (
              <Button
                variant="success"
                ClassName="regular-button"
                onClick={() => submit()}
              >
                {" "}
                Send Email
              </Button>
            )}
            {sent && (
              <Button variant="primary" ClassName="regular-button" disabled>
                {" "}
                Emails Sent
              </Button>
            )}
          </Form>
        </Container>
      )}
      {loading && (
        <Container className="createEventPage">
          <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
        </Container>
      )}
    </>
  );
}

export default SendParticipantsEmail;
