import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Alert from "react-bootstrap/Alert";
import Button from "@restart/ui/esm/Button";
const { REACT_APP_API_URL } = process.env;

function Users(props) {
  let history = useHistory();
  const [currentFilter, setCurrentFilter] = useState("All");
  const [listOfUsers, setUsers] = useState([]);
  const [filteredData, setFilteredData] = useState(listOfUsers);
  const [mode, setMode] = useState("Add");
  const [categories, setCategories] = useState([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const { authState, setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          if (props.location.state !== undefined) {
            if (props.location.state.reason) {
              //Show your alert here
              setShowAlert(true);
              handleVisible(props.location.state.reason, "success");
              history.replace();
            }
          }

          setMode("Add");
          setCurrentFilter("all");
          // Obtain list of users
          loadUsersList(currentFilter);

          //Obtain list of Event Groups
          axios
            .get(`${REACT_APP_API_URL}inviteeventgroups`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((response) => {
              setCategories(response.data);
              setLoading(false);
            });
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, setMode]);

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  const loadUsersList = (filter) => {
    // Obtain list of users
    axios
      .get(`${REACT_APP_API_URL}users/withFilter/${filter}`)
      .then((response) => {
        setUsers(response.data);
        setFilteredData(response.data);
        setSearchInputValue("");
      });
  };

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    setSearchInputValue(event.target.value);
    //console.log(value);
    result = listOfUsers.filter((data) => {
      return data.name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredData(result);
  };

  const changeCategorie = (categorie) => {
    setCurrentFilter(categorie);
    loadUsersList(categorie);
  };

  return (
    <>
      {showAlert && (
        <Alert show={visibleAlert} variant={variant}>
          {alertText}
        </Alert>
      )}
      {!loading && (
        <Container className="usersPage">
          <Row>
            <Col>
              <Button
                variant="success"
                onClick={() => history.push(`/newuser/`)}
              >
                {" "}
                New User
              </Button>
              <input
                type="text"
                className="search-users-input"
                placeholder="Search..."
                value={searchInputValue}
                onChange={(event) => handleSearch(event)}
              />
              <Card className="eventUsers">
                <Card.Header>
                  <Nav variant="tabs" defaultActiveKey="#All">
                    {categories.map((value, key) => {
                      const { eventGroupName } = value;
                      const link = "#".concat(eventGroupName);
                      return (
                        <Nav.Item>
                          <Nav.Link
                            href={link}
                            onClick={() => {
                              changeCategorie(eventGroupName);
                            }}
                          >
                            {eventGroupName}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Card.Header>
                <Card.Body>
                  {filteredData.map((value, key) => {
                    return (
                      <Card className="singleUserDetails" key={key}>
                        <Card.Body>
                          <Container>
                            <Row>
                              <Col>
                                <p className="mb-0" align="left">
                                  {value.name}
                                </p>
                              </Col>
                              <Col float="right">
                                <p className="mb-0" align="right">
                                  {value.memberId}{" "}
                                  <i
                                    className="fas fa-edit edit-user"
                                    onClick={() => {
                                      history.push(`/edituser/${value.id}`);
                                    }}
                                  ></i>
                                </p>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {loading && (
        <Container className="createEventPage">
          <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
        </Container>
      )}
    </>
  );
}

export default Users;
