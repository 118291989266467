import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
const { REACT_APP_API_URL } = process.env;

function NewEmailTemplate() {
  let history = useHistory();

  const [listOfInviteEventGroups, setListOfInviteEventGroups] = useState([]);
  const [newInviteEventGroupId, setNewInviteEventGroupId] = useState(0);
  const [newType, setNewType] = useState("");
  const [newSubject, setNewSubject] = useState("");
  const [newBody, setNewBody] = useState("");
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");
  const [loading, setLoading] = useState(true);

  const { authState, setAuthState } = useContext(AuthContext);

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          // Obtain list of groups
          axios
            .get(`${REACT_APP_API_URL}inviteeventgroups`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((response) => {
              setListOfInviteEventGroups(response.data);
              setLoading(false);
            });
          window.scrollTo(0, 0);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  const addGroup = () => {
    const data = {
      InviteEventGroupId: newInviteEventGroupId,
      type: newType,
      subject: newSubject,
      body: newBody,
    };
    axios
      .post(`${REACT_APP_API_URL}emailtemplates/`, data, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        setNewInviteEventGroupId(0);
        setNewType("");
        setNewSubject("");
        setNewBody("");
        window.scrollTo(0, 0);
        handleVisible("Email Template Added", "success");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        window.scrollTo(0, 0);
        handleVisible(error.response.data.msg, "danger");
      });
  };

  return (
    <>
      <Alert show={visibleAlert} variant={variant}>
        {alertText}
      </Alert>
      {!loading && (
        <Container className="createUserPage">
          <Row>
            <Col>
              <Form className="formContainer">
                <label for="groupName">Group Name</label>
                <select
                  name="groupName"
                  id="inputCreateUser"
                  value={newInviteEventGroupId}
                  onChange={(event) => {
                    setNewInviteEventGroupId(event.target.value);
                  }}
                >
                  {listOfInviteEventGroups.map((value, key) => {
                    return (
                      <option value={value.id}>{value.eventGroupName}</option>
                    );
                  })}
                </select>
                <label for="name">Type</label>
                <input
                  type="text"
                  placeholder="Type..."
                  autoComplete="off"
                  name="type"
                  value={newType}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewType(event.target.value);
                  }}
                />
                <label for="name">Subject</label>
                <input
                  type="text"
                  placeholder="Subject..."
                  autoComplete="off"
                  name="subject"
                  value={newSubject}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewSubject(event.target.value);
                  }}
                />
                <label for="name">Body</label>
                <input
                  type="text"
                  placeholder="Body..."
                  autoComplete="off"
                  name="body"
                  value={newBody}
                  id="inputCreateUser"
                  onChange={(event) => {
                    setNewBody(event.target.value);
                  }}
                />
                <Button
                  variant="success"
                  ClassName="regular-button"
                  onClick={() => addGroup()}
                >
                  {" "}
                  Add
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
      {loading && (
        <Container className="createEventPage">
          <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
        </Container>
      )}
    </>
  );
}

export default NewEmailTemplate;
