import React, { useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { Oval } from "react-loader-spinner";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
const { REACT_APP_API_URL } = process.env;

function EditEvent() {
  let { id } = useParams();
  let history = useHistory();

  const [value, setValue] = useState();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");
  const [listOfInviteEventGroups, setListOfInviteEventGroups] = useState([]);
  const [listOfGroupTypes, setListOfGroupTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  const { authState, setAuthState } = useContext(AuthContext);

  const initialValue = useRef({
    date: "",
    event: "",
    course: "",
    holes: "",
    startTime: "",
    secondaryStartTime: "",
    duration: "",
    places_total: "",
    type: "",
    groupName: "",
    groupType: "",
  });

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
          // Obtain details of the event
          axios
            .get(`${REACT_APP_API_URL}events/byId/${id}`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((response) => {
              //setEventObject(response.data);

              let date = new Date(response.data.date);
              const offset = date.getTimezoneOffset();
              date = new Date(date.getTime() - offset * 60 * 1000);
              let formatedDate = date.toISOString().split("T")[0];

              initialValue.current.date = formatedDate;
              initialValue.current.event = response.data.event;
              initialValue.current.course = response.data.course;
              initialValue.current.holes = response.data.holes;
              initialValue.current.startTime = response.data.startTime;
              initialValue.current.secondaryStartTime =
                response.data.secondaryStartTime;
              initialValue.current.duration = response.data.duration;
              initialValue.current.places_total = response.data.places_total;
              initialValue.current.type = response.data.type;
              initialValue.current.groupName = response.data.groupName;
              initialValue.current.groupType = response.data.groupType;

              // Obtain list of groups
              axios
                .get(`${REACT_APP_API_URL}inviteeventgroups`, {
                  headers: {
                    accessToken: localStorage.getItem("accessToken"),
                  },
                })
                .then((response) => {
                  setListOfInviteEventGroups(response.data);
                  console.log(listOfInviteEventGroups); //TEMPORARY
                  // Obtain list of groups types
                  axios
                    .get(`${REACT_APP_API_URL}grouptypes`, {
                      headers: {
                        accessToken: localStorage.getItem("accessToken"),
                      },
                    })
                    .then((response) => {
                      setListOfGroupTypes(response.data);
                      setLoading(false);
                    });
                });

              setValue({});
              console.log(value);
            });
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVisible = (text, variant) => {
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const validationSchema = Yup.object().shape({
    date: Yup.date()
      .required("You must input a date")
      .default(today)
      .min(today, "Date cannot be in the past"),
    event: Yup.string().required("Required"),
    course: Yup.string().required("Required"),
    holes: Yup.number()
      .min(1, "Minimum 1 hole")
      .max(18, "Maximum 18 holes")
      .required(),
    startTime: Yup.string().required("Required"),
    places_total: Yup.number().min(1, "Minimum 1 spot"),
    type: Yup.string().required("Required"),
    groupName: Yup.string().required("Required"),
    groupType: Yup.string().required("Required"),
  });

  const onSubmit = (formData) => {
    const data = {
      date: formData.date,
      event: formData.event,
      course: formData.course,
      holes: formData.holes,
      startTime: formData.startTime,
      secondaryStartTime: formData.secondaryStartTime,
      duration: formData.duration,
      places_total: formData.places_total,
      type: formData.type,
      groupNumber: 0,
      groupName: formData.groupName,
      groupType: formData.groupType,
    };

    //Obtain Group Number
    axios
      .get(`${REACT_APP_API_URL}inviteeventgroups/byname/${data.groupName}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        data.groupNumber = response.data.groupNumber;
        axios
          .put(`${REACT_APP_API_URL}events/${id}`, data, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
            //recalculateSpots();
            history.push(`/events/byId/${id}`);
          })
          .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            window.scrollTo(0, 0);
            handleVisible(error.response.data.msg, "danger");
          });
      });
  };

  const deleteEvent = () => {
    axios
      .delete(`${REACT_APP_API_URL}events/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        history.push(`/`);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        window.scrollTo(0, 0);
        handleVisible(error.response.data.msg, "danger");
      });
  };

  return (
    <>
      <Alert show={visibleAlert} variant={variant}>
        {alertText}
      </Alert>
      <Container className="createEventPage">
        {!loading && (
          <Formik
            initialValues={initialValue.current}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form className="formContainer">
              <label>Group Name</label>
              <ErrorMessage name="groupName" component="span" />
              <Field as="select" id="inputCreateEvent" name="groupName">
                {listOfInviteEventGroups.map((value, key) => {
                  return (
                    <option value={value.eventGroupName}>
                      {value.eventGroupName}
                    </option>
                  );
                })}
              </Field>
              <label>Date</label>
              <ErrorMessage name="date" component="span" />
              <Field id="inputCreateEvent" name="date" type="date" />
              <label>Event</label>
              <ErrorMessage name="event" component="span" />
              <Field id="inputCreateEvent" name="event" />
              <label>Course</label>
              <ErrorMessage name="course" component="span" />
              <Field id="inputCreateEvent" name="course" />
              <label>Holes</label>
              <ErrorMessage name="holes" component="span" />
              <Field id="inputCreateEvent" name="holes" type="number" />
              <label>Start Time</label>
              <ErrorMessage name="startTime" component="span" />
              <Field id="inputCreateEvent" name="startTime" type="time" />
              <label>Secondary Start Time</label>
              <ErrorMessage name="secondaryStartTime" component="span" />
              <Field
                id="inputCreateEvent"
                name="secondaryStartTime"
                type="time"
              />
              <label>Total Spots</label>
              <ErrorMessage name="places_total" component="span" />
              <Field id="inputCreateEvent" name="places_total" type="number" />
              <label>Type</label>
              <ErrorMessage name="type" component="span" />
              <Field id="inputCreateEvent" name="type" />
              <label>Group Type</label>
              <ErrorMessage name="groupType" component="span" />
              <Field as="select" id="inputCreateEvent" name="groupType">
                {listOfGroupTypes.map((value, key) => {
                  return (
                    <option value={value.groupName}>{value.groupName}</option>
                  );
                })}
              </Field>
              <Button variant="success" type="submit">
                Save Event
              </Button>
              <Button variant="danger" onClick={deleteEvent}>
                Delete Event
              </Button>
            </Form>
          </Formik>
        )}
        {loading && (
          <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
        )}
      </Container>
    </>
  );
}

export default EditEvent;
