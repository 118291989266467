import React, { useEffect, useRef, useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";

// React Bootstrap Components
import Button from "react-bootstrap/Button";
const { REACT_APP_API_URL } = process.env;

function AddGuest(props) {
  const id = props.id;

  const initialValue = useRef({
    name: "",
    gender: "",
    email: "",
    handicap: "",
    federation: "",
  });

  const { authState, setAuthState } = useContext(AuthContext);
  const [sentForm, setSentForm] = useState(false);
  let history = useHistory();

  useEffect(() => {
    // Obtain authenticated user
    axios
      .get(`${REACT_APP_API_URL}users/auth`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
          history.push(`/users/login`);
        } else {
          setAuthState({
            name: response.data.name,
            id: response.data.id,
            privileges: response.data.privileges,
            status: true,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email('Please enter a valid email').required("Required"),
    handicap: Yup.number('Please enter a valid Handicap').required("Required"),
    federation: Yup.string('Please enter a valid Federation'),
  });

  
  const onSubmit = async (formData) => {
    let guestId = 0;
    const handicapWithComma = formData.handicap.replace(".", ",");

    const data = {
      name: formData.name,
      gender: formData.gender,
      email: formData.email,
      handicap: handicapWithComma,
      handicapDec: handicapWithComma,
      federation: formData.federation,
      EventId: id,
    };

    // Add Guest to Guests table
    axios
      .post(`${REACT_APP_API_URL}guests/`, data, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then(async (response) => {
        guestId = response.data;

        // Obtain available spots for the Event
        await axios
          .get(`${REACT_APP_API_URL}events/byId/${id}`, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then(async (response) => {
            let waitlist = 1;
            if (response.data.places_available > 0) {
              waitlist = 0;
            }
            // Add registry to EventUsers table with indication that it's a guest
            await axios
              .post(
                `${REACT_APP_API_URL}eventusers`,
                {
                  GuestId: guestId,
                  EventId: id,
                  guest: 1,
                  waitlist,
                  observations: "Guest",
                },
                {
                  headers: {
                    accessToken: localStorage.getItem("accessToken"),
                  },
                }
              )
              .then((response) => {
                console.log(response);
                formData.name = "";
                formData.email = "";
                formData.handicap = "";
                props.es6Function();
              });
          });
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
      setSentForm(true);
  };

  return (
    <>
      <Formik
        initialValues={initialValue.current}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form className="formContainer">
          <label>Name</label>
          <ErrorMessage name="name" component="span" />
          <Field id="inputCreateEvent" name="name" type="text" />
          <label>Gender</label>
          <ErrorMessage name="gender" component="span"></ErrorMessage>
          <Field
            id="inputCreateEvent"
            name="gender"
            component="select"
            placeholder="Select options"
          >
            <option style={{ display: "none" }} value="0">
              Select Options
            </option>
            <option value="F">Female</option>
            <option value="M">Male</option>
          </Field>
          <label>Email</label>
          <ErrorMessage name="email" component="span" />
          <Field id="inputCreateEvent" name="email" type="email"/>
          <label>Handicap</label>
          <ErrorMessage name="handicap" component="span" />
          <Field id="inputCreateEvent" name="handicap" type="text" placeholder='0' />
          <label>Federation Number</label>
          <ErrorMessage name="federation" component="span" />
          <Field id="inputCreateEvent" name="federation" type="text" />
          <Button variant="success" type="submit">
            Add Guest
          </Button>
          {sentForm && <p className="custom-validation">Guest added succesfully!</p>}
        </Form>
      </Formik>
    </>
  );
}

export default AddGuest;
